import { Image } from '@graphcommerce/image'
import { Accordion, AccordionDetails, AccordionSummary, Tab, styled } from '@mui/material'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ReactElement, useState } from 'react'
import expandIcon from '../../assets/images/arrow-down.svg'
import missingFile from '../../assets/images/missing-file.png'
import missingImageIcon from '../../assets/images/missing-image-icon.svg'

import { ResponsiveImageGridItem } from '../Image/ResponsiveImageGridItem'
import { MegaMenuItem } from './MegaMenuHeaderTabs'

export const MenuAccordion = styled(Accordion)({
  border: 0,
  background: 'none',

  '&:before': {
    display: 'none',
  },
})

type SubHeaderTabsMobileProps = {
  menuItems?: MegaMenuItem[]
  className?: string
  onClose: () => void
}

export interface VerticalTabProps {
  label: string
  id: string
  value: string
  'aria-controls': string
  onHover?: () => void
  icon?: ReactElement<any>
  onClick?: () => void
}

export const VerticalTab = styled((props: VerticalTabProps) => (
  <Tab
    disableRipple
    {...props}
    onMouseEnter={props.onHover}
    icon={props.icon}
    onClick={() => props.onClick && props?.onClick()}
  />
))(() => ({
  whiteSpace: 'nowrap',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyItems: 'start',
  justifyContent: 'start',
  gap: 20,
  marginBottom: 0,
  minHeight: 56,
  color: 'rgba(0, 0, 0, 0.6)',
  'img.MuiTab-iconWrapper': {
    marginBottom: 0,
    height: 30,
    width: 30,
  },
  ':hover': {
    backgroundColor: 'rgba(97,51,148,0.1)',
  },
}))

/**
 * This is a test doc
 *
 * @param SubHeaderTabsMobileProps @
 */
export function SubHeaderTabsMobile(props: SubHeaderTabsMobileProps) {
  const { menuItems, className, onClose } = props
  const a11yProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  })
  const router = useRouter()

  const handleNavigate = async (href: string) => {
    await router.push(href)
    onClose()
  }
  const [openedIndex, setOpenedIndex] = useState<number>(-1)

  return (
    <div className={`ml-2 flex w-full flex-col ${className}`}>
      {menuItems?.map((item, index) => (
        <MenuAccordion
          elevation={0}
          disableGutters
          expanded={index === openedIndex}
          onClick={() => {
            if (index === openedIndex) {
              setOpenedIndex(-1)
            } else {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              setOpenedIndex(index)
            }
          }}
        >
          <AccordionSummary
            expandIcon={
              item?.children && item?.children?.length > 0 ? (
                <Image
                  src={expandIcon}
                  alt='expand or close'
                  layout='fixed'
                  unoptimized
                  height={40}
                  width={40}
                />
              ) : null
            }
            sx={{
              padding: 0,
              margin: 0,
            }}
          >
            {item.url ? (
              <Link
                href={`/${item.url}`}
                className='flex  w-full flex-row items-center justify-start gap-2 text-black hover:text-bright-templi-purple'
                onClick={() => onClose()}
              >
                {item?.image ? (
                  <Image
                    unoptimized
                    layout='fixed'
                    src={item?.image?.url ?? ''}
                    alt={item?.image?.alt ?? ''}
                    height={30}
                    width={30}
                    className='flex h-[30px] w-[30px] items-center justify-center rounded-[50%]'
                  />
                ) : (
                  <Image
                    unoptimized
                    layout='fixed'
                    src={missingImageIcon}
                    height={30}
                    width={30}
                    className='flex h-[30px] w-[30px] items-center justify-center rounded-[50%]'
                  />
                )}
                <h3 className='Type-XL-Medium normal-case hover:text-bright-templi-purple'>
                  {item.title}
                </h3>
              </Link>
            ) : (
              <span className='flex  w-full flex-row items-center justify-start gap-2 text-black'>
                {item?.image ? (
                  <Image
                    unoptimized
                    layout='fixed'
                    src={item?.image?.url ?? ''}
                    alt={item?.image?.alt ?? ''}
                    height={30}
                    width={30}
                    className='flex h-[30px] w-[30px] items-center justify-center rounded-[50%]'
                  />
                ) : (
                  <Image
                    unoptimized
                    layout='fixed'
                    src={missingImageIcon}
                    height={30}
                    width={30}
                    className='flex h-[30px] w-[30px] items-center justify-center rounded-[50%]'
                  />
                )}
                <h3 className='Type-XL-Medium normal-case'>{item.title}</h3>
              </span>
            )}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0, margin: 0, border: 0 }}>
            <div className='my-4 grid grid-cols-2 items-start justify-end gap-x-4 gap-y-7'>
              {item?.children?.map((category) => (
                <div className='flex flex-col items-start justify-start gap-1'>
                  {category?.url && (
                    <Link
                      href={`/${category.url}`}
                      onClick={() => onClose()}
                      key={category.title}
                      className='mb-1  w-full text-black hover:text-bright-templi-purple'
                    >
                      <span className='Type-XL-Medium text-start '>{category.title}</span>
                    </Link>
                  )}
                  {!category?.url && (
                    <span className='Type-XL-Medium mb-1 text-start text-70-grey '>
                      {category.title}
                    </span>
                  )}
                  {category.children?.map((child) => (
                    <div>
                      {child?.url && (
                        <Link
                          href={`/${child.url}`}
                          onClick={() => onClose()}
                          key={child.title}
                          className='w-full  text-black hover:text-bright-templi-purple'
                        >
                          <span className='Type-XL-Regular text-start'>{child.title}</span>
                        </Link>
                      )}
                      {!child?.url && (
                        <span className='Type-XL-Regular my-1 text-start text-70-grey'>
                          {child.title}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </AccordionDetails>
        </MenuAccordion>
      ))}
    </div>
  )
}
